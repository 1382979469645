import * as XLSX from 'sheetjs-style';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import { firstRowStyles, boldText } from '../const';
import { StyleBase } from '../RoutePerforming/types';

const getTableHeadersStyles = () => {
  const cells = ['A6', 'B6', 'C6', 'D6', 'E6', 'F6', 'G6', 'H6', 'I6'];

  return cells.map(cell => ({
    [cell]: {
      s: boldText
    }
  }));
};

const styleBase = {
  A1: {
    s: firstRowStyles
  },
  A2: {
    s: {
      ...boldText
    }
  },
  A3: {
    s: {
      ...boldText
    }
  },
  A4: {
    s: {
      ...boldText
    }
  },
  ...getTableHeadersStyles()
} as StyleBase;

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 5, c: 0 };

export const getStyles = () =>
  getStylesForReportWithTable(styleBase, TABLE_START_ADDRESS);
