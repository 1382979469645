import { flatten } from 'lodash';
import { emptyDataPlaceholder } from 'src/utils/text';
import { HourlyDeparture } from './types';
import { ReportTableConfig } from '../types';
import { ReportFilterData } from '../../types';

export const title = '2. Звіт погодинного випуску автобусів на маршрут';

export const getTableConfig = (
  filterData: ReportFilterData,
  report: HourlyDeparture
): ReportTableConfig => {
  const times = report.routes[0]?.timeSpans || [];

  const formattedWorkingHours = times.map(
    ({ from, to }) => `з ${from} до ${to}`
  );

  const data = [
    [{ colSpan: 12, value: title }],
    [{ value: 'Перевізник' }, { colSpan: 11, value: filterData.counteragent }],
    [{ value: 'Дата' }, { colSpan: 11, value: filterData.date }],
    [],
    [
      { rowSpan: 3, value: 'Назва маршруту' },
      { colSpan: formattedWorkingHours.length * 2, value: 'Години доби' },
      { rowSpan: 3, value: 'Коефіцієнт випуску на маршрут, %' }
    ],
    formattedWorkingHours.map(value => ({ value, colSpan: 2 })),
    [
      ...flatten(
        formattedWorkingHours.map(() => [{ value: 'План' }, { value: 'Факт' }])
      )
    ],
    ...report.routes.map(route =>
      flatten([
        { value: emptyDataPlaceholder(route.name) },
        ...route.timeSpans.map(({ planned, fact }) => [
          { value: emptyDataPlaceholder(planned) },
          { value: emptyDataPlaceholder(fact) }
        ]),
        {
          value: emptyDataPlaceholder(
            `${Math.round(route.plannedFactRatio * 100)}%`
          )
        }
      ])
    ),
    [
      { colSpan: Math.max(formattedWorkingHours.length * 2 - 1, 0) },
      { colSpan: 2, value: 'В середньому за день роботи:' },
      {
        value: emptyDataPlaceholder(
          `${Math.round(report.averagePlannedFactRatioPerDay * 100)}%`
        )
      }
    ]
  ];

  return data;
};
