import { flatten } from 'lodash';
import { emptyDataPlaceholder } from 'src/utils/text';
import { convertSecondsToTimestamp } from 'src/utils/date';
import moment from 'moment';
import { DETAILED_DATE_FORMAT } from 'src/config';
import { GraphicityPerformance, ReportBusStop } from './types';
import { ReportTableConfig } from '../types';
import { ReportFilterData } from '../../types';

export const title =
  '5. Звіт про відвідування зупинок та виконання графічності';

const formatDeviation = (deviationInSec: number) => {
  if (!deviationInSec) return '-';

  return (
    (deviationInSec > 0 ? '+' : '-') +
    convertSecondsToTimestamp(deviationInSec, 'HH:mm')
  );
};

const formatBusStop = (busStop: ReportBusStop) => {
  const timeFields = [
    'plannedDateArrive',
    'plannedDateDeparture',
    'factDateArrive',
    'factDateDeparture'
  ];
  const busStopFieldOrder: (keyof (ReportBusStop & {
    busStopName: string;
  }))[] = [
    'busStopName',
    'plannedDateArrive',
    'plannedDateDeparture',
    'factDateArrive',
    'factDateDeparture',
    'deviationInSec',
    'notes'
  ];

  return busStopFieldOrder.map(field => {
    const value = busStop[field];

    if (field === 'busStopName') {
      return {
        value: emptyDataPlaceholder(
          `${busStop.busStopName} (${busStop.busStopCode})`
        )
      };
    }

    if (field === 'deviationInSec') {
      return {
        value: emptyDataPlaceholder(formatDeviation(busStop.deviationInSec))
      };
    }

    if (timeFields.includes(field)) {
      return {
        value: value
          ? moment(value as string, DETAILED_DATE_FORMAT).format(
              'DD.MM.YYYY HH:mm'
            )
          : '-'
      };
    }

    return {
      value: emptyDataPlaceholder(value)
    };
  });
};

const convertRide = (report: GraphicityPerformance) =>
  flatten(
    report.map(ride => {
      const { vehicles, iterationNumber, departureName } = ride;

      const result: ReportTableConfig = [];

      vehicles.forEach((vehicle, vehicleIndex) => {
        const busStopValues: ReportTableConfig = [];

        const totalBusStopsAmount = vehicles.reduce((prev, curr) => {
          return prev + curr.busStops.length;
        }, 0);

        vehicle.busStops.forEach((busStop, busStopIndex) => {
          // Array of values for bus stop { value: '' }
          const formattedBusStop = formatBusStop(busStop);

          // Vehicle with 0 index should also contain { value: sequenceNumber }
          // Bus stop with 0 index should also contain { value: licensePlate }

          if (vehicleIndex === 0) {
            if (busStopIndex === 0) {
              const newValue = [
                { value: departureName, rowSpan: totalBusStopsAmount },
                { value: iterationNumber, rowSpan: totalBusStopsAmount },
                {
                  value: vehicle.vehicleLicensePlate,
                  rowSpan: vehicle.busStops.length
                },
                ...formattedBusStop
              ];
              busStopValues.push(newValue);
            } else {
              busStopValues.push(formattedBusStop);
            }
          } else if (busStopIndex === 0) {
            const newValue = [
              {
                value: vehicle.vehicleLicensePlate,
                rowSpan: vehicle.busStops.length
              },
              ...formattedBusStop
            ];
            busStopValues.push(newValue);
          } else {
            busStopValues.push(formattedBusStop);
          }
        });

        result.push(...busStopValues);
      });
      return result;
    })
  );

export const getTableConfig = (
  filterData: ReportFilterData,
  report: GraphicityPerformance
): ReportTableConfig => {
  return [
    [{ value: title, colSpan: 8 }],
    [{ value: 'Перевізник' }, { value: filterData.counteragent, colSpan: 7 }],
    [
      { value: 'Маршрут' },
      { value: `${filterData.routeNumber}, ${filterData.route}`, colSpan: 7 }
    ],
    [
      { value: 'Дата' },
      {
        value: filterData.date,
        colSpan: 7
      }
    ],
    [],
    [
      { value: 'Випуск' },
      { value: 'Їздка' },
      { value: 'Транспортний засіб' },
      { value: 'Геозона' },
      { value: 'Плановий час прибуття' },
      { value: "Плановий час від'їзду" },
      { value: 'Фактичний час прибуття' },
      { value: "Фактичний час від'їзду" },
      { value: 'Відхилення від графіку' },
      { value: 'Примітки' }
    ],
    ...convertRide(report)
  ];
};
