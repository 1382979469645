import * as XLSX from 'sheetjs-style';

export const boldText = {
  font: {
    bold: true
  }
};
export const centered: Partial<XLSX.CellObject['s']> = {
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true
  }
};

export const transparentBorderStyle = {
  style: 'medium',
  color: { rgb: '00FFFFFF' }
};

// @ts-ignore
export const firstRowStyles: XLSX.CellObject['s'] = {
  ...boldText,
  ...centered
};

const borderThinBlack: object | any = { style: 'thin', color: { rgb: '0000' } };

export const borderStyle = {
  top: borderThinBlack,
  bottom: borderThinBlack,
  left: borderThinBlack,
  right: borderThinBlack
};

export const summaryRowFillColor = '79aee7';
