import * as XLSX from 'sheetjs-style';
import getStylesForReportWithTable from '../../utils/getStylesForReportWithTable';
import { boldText, centered, firstRowStyles } from '../const';
import { StyleBase } from '../RoutePerforming/types';

const styleBase: StyleBase = {
  A1: {
    s: firstRowStyles
  },
  A2: {
    s: {
      ...boldText
    }
  },
  A3: {
    s: {
      ...boldText
    }
  },
  B2: {
    s: {
      ...centered
    }
  },
  B3: {
    s: {
      ...centered
    }
  }
};

const TABLE_START_ADDRESS: XLSX.CellAddress = { r: 5, c: 0 };

export const getStyles = () =>
  getStylesForReportWithTable(styleBase, TABLE_START_ADDRESS);
