import { sortBy } from 'lodash';
import { extractNumericPart } from 'src/utils/extractNumericPart';
import { ReportFilterData } from '../../types';
import { ReportTableConfig, TableRow } from '../types';
import { WorkOfCarrierReport, Route, WorkOfCarrierCounteragent } from './types';

const transformRoutes = (routes: Route[]) =>
  routes.map(route => [
    {
      value: route.routeName
    },
    {
      value: route.releaseRatio.toFixed(3)
    },
    {
      value: route.presenceRatio.toFixed(3)
    },
    {
      value: route.completedIterationsRatio.toFixed(3)
    },
    {
      value: route.graphicityRatio.toFixed(3)
    }
  ]);

export const title = '4. Звіт про роботу перевізника';

export const getCounteragentSection = (
  counteragent: WorkOfCarrierCounteragent
): TableRow[] => {
  const routesSorted = sortBy(counteragent.routes, r =>
    extractNumericPart(r.routeName)
  );

  return [
    [
      {
        colSpan: 5,
        value: counteragent.counteragentName
      }
    ],
    ...transformRoutes(routesSorted),
    [
      { value: 'Всього' },
      { value: counteragent.totalReleaseRatio.toFixed(3) },
      { value: counteragent.totalPresenceRatio.toFixed(3) },
      { value: counteragent.totalCompletedIterationsRatio.toFixed(3) },
      { value: counteragent.totalGraphicityRatio.toFixed(3) }
    ]
  ];
};

export const getTableConfig = (
  filterData: ReportFilterData,
  report: WorkOfCarrierReport
): ReportTableConfig => [
  [{ value: title, colSpan: 5 }],
  [{ value: 'Перевізник' }, { value: filterData.counteragent, colSpan: 4 }],
  [{ value: 'Дата' }, { value: filterData.date, colSpan: 4 }],
  [],
  [],
  [
    { value: 'Маршрут' },
    { value: 'Коефіцієнт випуску на маршрут, %' },
    { value: 'Коефіцієнт присутності на маршруті, %' },
    { value: 'Частка виконаних рейсів, % ' },
    { value: 'Графічність, %  ' }
  ],
  ...report.counteragents.map(getCounteragentSection).flat()
];
